/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import $ from 'jquery';
import { handleUndefined, postDataRequestPublic } from '../../../common/commonfunctions';
import { LoadingPage } from '../../LoadingPage/LoadingPage';
import config from '../../../config';
import './inquiry.css';
import FormGenerator from './FormGenerator';
import { Box, Button, Dialog, DialogContent, IconButton, Link, Paper, Typography, styled } from '@mui/material';
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';
import { CreateInquiryContact, GetInquiryFormResponse, InquiryById, createInquiry, updateInquiry } from '../../../Api Mutation/InquiryForm';
import { showNotification } from '../../../components/Notification/ToastifyNotifications';
import Payment from '../../Setting/PaymentConnection/Payment';
import BusinessFavicon from '../../../common/BusinessFavicon';
import { BrandSettingByLocation, Entity } from '../../../Api Mutation/EntityApi';
import PoweredByLogo from '../../../common/PoweredByLogo';

export default function PublicInquiryForm() {
  const {
    formid,
  } = useParams([]);
  const search = window?.location?.search;
  const params = new URLSearchParams(search);
  const inquiryId = params.get('inquiryid');
  const [content, setContent] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const [popupThankYouMessage, setPopupThankYouMessage] = useState();
  const [popupThankYouButtonText, setPopupThankYouButtonText] = useState();
  const [popupThankYouUrl, setPopupThankYouUrl] = useState('');
  const [showThankYouButton, setShowThankYouButton] = useState(false);
  const [thankYouFileUrl, setThankYouFileUrl] = useState();
  const [open, setOpen] = useState(false);
  const [callOutText, setCallOutText] = useState('');
  const [callOutBody, setCallOutBody] = useState('');
  const [rows, setRows] = useState(12);
  const [thanksMsg, setThanksMsg] = useState(popupThankYouMessage);
  const [termDesc, setTermDesc] = useState();
  const [enableTerm, setEnableTerm] = useState(false);
  const [enableCaptcha, setEnableCaptcha] = useState(false);
  const [enablePublicLogo, setEnablePublicLogo] = useState(false);
  const [btnName, setBtnName] = useState('Subscribe Now');
  const [colorCode, setColorCode] = useState('#005eb2');
  const [backgroundColorCode, setBackgroundColorCode] = useState('transparent');
  const [foreColor, setForeColor] = useState('#000');
  const [btnPos, setBtnPos] = useState('right');
  const [textPos, setTextPos] = useState('left');
  const [radioPos, setRadioPos] = useState('vertical');
  const [checkBoxPos, setCheckBoxPos] = useState('vertical');
  const [btnThemeColor, setBtnThemeColor] = useState('#005eb2');
  const [btnTextColor, setBtnTextColor] = useState('#000');
  const [paidForm, setPaidForm] = useState(false);
  const [fontColorCode, setFontColorCode] = useState('black');
  const [formPrice, setFormPrice] = useState(0);
  const [contactData, setContactData] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);
  const [isPayment, setIsPayment] = useState(false);
  const [paymentId, setPaymentId] = useState([]);
  const [submittedData, setData] = useState('');
  const [defaultCountryPhoneCode, setDefaultCountryPhoneCode] = useState('1');
  const [eventType, setEventType] = useState('single-page');
  const [brandImage, setBrandImage] = useState();
  const [locationBrandImage, setLocationBrandImage] = useState(null);
  const [favIcon, setFavicon] = useState(null);
  const [locationid, setLocationId] = useState('');
  const [businessid, setBusinessId] = useState('');
  const [formWidth, setFormWidth] = useState('50');

  const StyledPaper = styled(Paper)(({ theme }) => ({
    width: `${formWidth}%`,
    height: 'auto',
    padding: 42,
    justifyContent: 'center',
    fontFamily: 'Roboto',
    background: backgroundColorCode,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 15,
      height: 'auto',
    },
    border: eventType === 'single-page' ?   '1px solid lightgrey' : 'none',
    boxShadow: 'none',
    margin: 'auto',
    marginTop: eventType === 'single-page' ?   '2rem' : '10rem',
    [theme.breakpoints.down('md')]: {
      marginTop: eventType === 'single-page' ?   '1rem !important' : '10rem !important',
    },
  }));

  const CalloutTypo = styled(Typography)(({ theme }) => ({
    color: foreColor,
    textAlign: textPos,
    fontSize: 20,
    fontFamily: 'Roboto',
    fontWeight: 600,
  }));

  const StyledTypoBody = styled(Typography)(({ theme }) => ({
    color: foreColor,
    textAlign: textPos,
    fontWeight: '400 !important',
    fontFamily: 'Roboto',
    fontSize: 14,
  }));

  useEffect(() => {
    if (formid) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: GetInquiryFormResponse,
        variables: {
          inquiryformid: formid,
        },
      }).then((res) => {
        setShowThankYouButton(res?.data?.inquiryform?.isshowthanksbutton);
        setPopupThankYouUrl(res?.data?.inquiryform?.thanksbuttonlink);
        setPopupThankYouButtonText(res?.data?.inquiryform?.thanksbuttontext);
        setPopupThankYouMessage(res?.data?.inquiryform?.thanksmessage);
        setContent(JSON.parse(res?.data?.inquiryform?.formdata));
        setThankYouFileUrl(res?.data?.inquiryform?.thanksbuttonfileurl);
        setCallOutText(res?.data?.inquiryform?.callouttext);
        setCallOutBody(res?.data?.inquiryform?.calloutbody);
        setFontColorCode(res?.data?.inquiryform?.fieldtextcolor);
        setRows(res?.data?.inquiryform?.selectedrows);
        setRadioPos(res?.data?.inquiryform?.radioview);
        setCheckBoxPos(res?.data?.inquiryform?.checkboxview);
        setFormWidth(res?.data?.inquiryform?.formwidth);
        setBtnName(res?.data?.inquiryform?.calloutbuttontext);
        setColorCode(res?.data?.inquiryform?.themecolor);
        setBackgroundColorCode((res?.data?.inquiryform?.backgroundcolor === '#ffffff' || res?.data?.inquiryform?.backgroundcolor === '#fff') ? 'transparent' : res?.data?.inquiryform?.backgroundcolor);
        setForeColor(res?.data?.inquiryform?.textcolor);
        setBtnPos(res?.data?.inquiryform?.calloutbtnposition);
        setTextPos(res?.data?.inquiryform?.textposition);
        if (res?.data?.inquiryform?.btnthemecolor) {
          setBtnThemeColor(res?.data?.inquiryform?.btnthemecolor);
          setBtnTextColor(res?.data?.inquiryform?.btntextcolor);
        }
        setTermDesc(res?.data?.inquiryform?.termdesc);
        setEnableTerm(res?.data?.inquiryform?.termenable);
        setEnableCaptcha(res?.data?.inquiryform?.captchaenable);
        setEnablePublicLogo(res?.data?.inquiryform?.isshowfooterlogo);
        setPaidForm(res?.data?.inquiryform?.ispaid);
        setFormPrice(res?.data?.inquiryform?.formprice);
        setEventType(res?.data?.inquiryform?.formtype);
        setLocationId(res?.data?.inquiryform?.locationid);
        setBusinessId(res?.data?.inquiryform?.businessid);
        setDataLoading(false);
      });
    }
  }, [formid]);


  const getContact = (cid) => {
    if (cid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `
        query contact($contactid: ID!){
          contact(contactid:$contactid){
            contactid,primaryemail,firstname,lastname,contactavatar,phonenumber,lifecyclestage,locationid,
            linkedin,createdby,updatedby,jobid,ownerid,createdAt,updatedAt,istargetlist,ispininchat,organization{
              organizationid,name,orgwebsite
            }
      }}
      `,
        variables: {
          contactid: cid,
        },
      }).then((res) => {
        if (res?.data?.contact) {
          setContactData(res?.data?.contact);
        }
      });
    }
  };

  const getOrganization = (oid) => {
    if (oid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `
              query organization($organizationid: ID!){
                organization(organizationid: $organizationid){
                      organizationid,name,locationid,orgphonenumber,orgwebsite,orgtwitter,orgfacebook,orgstatus,categories,employeesize,
                      yearsinbusiness,revenue,businessstartdate,typeofentity,orgmap,orglinkedin,orggmb,orgemail,orgyoutubeurl,
                      address{addressid,street1,street2,city,county,stateid,countryid,zip,country{countryid,name},state{stateid,name}},orgyelp,orgtripadvisor,
                      createdby,updatedby,phonecountryid,createdAt,updatedAt,orgattribute1,orgattribute2,orgattribute3,orgattribute4,
                  }
              }`,
        variables: {
          organizationid: oid,
        },
      }).then((res) => {
        if (res?.data?.organization) {
          setOrganizationData(res?.data?.organization);
        }
      });
    }
  };

  useEffect(() => {
    if (paymentId.length > 0) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: createInquiry,
        variables: {
          responsedata: JSON.stringify(submittedData),
          inquiryformid: formid,
          status: 'active',
          locationid,
          businessid,
          contactid: contactData?.contactid,
          organizationid: organizationData?.organizationid,
          paymentid: paymentId,
        },
      }).then((response) => {
        const respo = response?.data?.createInquiry;
        if (respo?.message) {
          showNotification(respo.message, 'success');
          setDataLoading(false);
        } else {
          $('form').trigger('reset');
          setOpen(true);
          setIsPayment(false);
          setDataLoading(false);
        }
      });
    }
  }, [paymentId]);

  useEffect(() => {
    if (inquiryId) {
      postDataRequestPublic(config.baseURLApi, {
        query: InquiryById,
        variables: {
          inquiryid: inquiryId,
        },
      }).then((res) => {
        if (res?.data?.inquiry?.responsedata) {
          setData(JSON.parse(res?.data?.inquiry?.responsedata));
        }
      });
    }
  }, [inquiryId]);

  const entityData = () => {
    postDataRequestPublic(config.baseURLApi, {
      query: Entity,
      variables: { entityid: locationid },
    })
      .then((res) => {
        if (res?.data?.entity) {
          setLocationBrandImage(res?.data?.entity?.logourl);
          setFavicon(res?.data?.entity?.favicon);
          if (res?.data?.entity?.defaultphonecountrycode) {
            setDefaultCountryPhoneCode(res?.data?.entity?.defaultphonecountrycode);
          }
          if (handleUndefined(res?.data?.entity?.parententityid)) {
            postDataRequestPublic(config.baseURLApi, {
              query: BrandSettingByLocation,
              variables: {
                locationid: handleUndefined(res?.data?.entity?.parententityid),
              },
            }).then((r) => setBrandImage(r?.data?.brandSettingByLocation?.logourl));
          }
        }
        setDataLoading(false);
      });
  };

  useEffect(() => {
    entityData();
  }, [locationid]);

  useEffect(() => {
    $('input, select, textarea').attr('autocomplete', 'off');
  });

  const onFormSubmit = (data) => {
    const firstname = data.find((i) => i?.dbfieldName === 'firstname')?.value || data.find((i) => i.name.includes('First Name'))?.value;
    const lastname = data.find((i) => i?.dbfieldName === 'lastname')?.value || data.find((i) => i.name.includes('Last Name'))?.value;
    const orgname = data.find((i) => i?.dbfieldName === 'name')?.value || data.find((i) => i.name.includes('Organization Name'))?.value;
    const msg = popupThankYouMessage?.replace(/{{Name}}/g, firstname ? `${firstname} ${lastname || ''}` : orgname || '');
    setThanksMsg(msg);
    setDataLoading(true);
    postDataRequestPublic(config.baseURLApi, {
      query: CreateInquiryContact,
      variables: {
        responsedata: JSON.stringify(data),
        inquiryformid: formid,
        locationid,
        businessid,
      },
    }).then((res) => {
      const result = res?.data?.createinquirycontact;
      if (result) {
        if (result.contactid) {
          getContact(result.contactid);
        } else if (result.organizationid) {
          getOrganization(result.organizationid);
        }
        if (inquiryId) {
          postDataRequestPublic(config.baseURLApi, {
            query: updateInquiry,
            variables: {
              inquiryid: inquiryId,
              responsedata: JSON.stringify(data),
              inquiryformid: formid,
              status: 'active',
              locationid,
              businessid,
              contactid: result.contactid,
              organizationid: result.organizationid,
            },
          }).then((response) => {
            if (response.data) {
              $('form').trigger('reset');
              setOpen(true);
              setDataLoading(false);
            } else {
              showNotification(res?.errors?.[0]?.message, 'error');
              setDataLoading(false);
            }
          });
        } else {
          if (paidForm) {
            showNotification('Please make payment to submit form..!', 'info');
            setData(data);
            setIsPayment(true);
          } else {
            postDataRequestPublic(config.baseURLApi, {
              query: createInquiry,
              variables: {
                responsedata: JSON.stringify(data),
                inquiryformid: formid,
                status: 'active',
                locationid,
                businessid,
                contactid: result.contactid,
                organizationid: result.organizationid,
              },
            }).then((response) => {
              if (response.data) {
                $('form').trigger('reset');
                setOpen(true);
                setDataLoading(false);
              } else {
                showNotification(res?.errors?.[0]?.message, 'error');
                setDataLoading(false);
              }
            });
          }
        }
      }
    });
  };

  return (
    <>
      <BusinessFavicon
        name={callOutText}
        logo={favIcon || locationBrandImage || brandImage}
      />
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
        fullWidth="fullWidth"
        maxWidth="xs"
      >
        <DialogContent id="responsive-dialog-title" style={{ padding: 20 }}>
        <Box textAlign="end" className='mb-2'>
            <IconButton onClick={() => setOpen(false)} style={{ padding: 0}}>
              <HighlightOff />
            </IconButton>
          </Box>
          <center>
            <CheckCircleOutline style={{ fontSize: 70, color: 'green' }} />
          </center>
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: thanksMsg }} />
          <br />
          {showThankYouButton && popupThankYouUrl
            && (
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => {
                  window.open(popupThankYouUrl, '_blank');
                }}
              >
                {popupThankYouButtonText}
              </Button>
            )}
          {showThankYouButton && thankYouFileUrl && (
            <Link
              href={thankYouFileUrl}
              target="_blank"
              download
            >
              <Button
                variant="contained"
                size="large"
                color="primary"
              >
                {popupThankYouButtonText}
              </Button>
            </Link>
          )}
          <center>
            <PoweredByLogo enablePublicLogo={enablePublicLogo} id={locationid} />
          </center>
        </DialogContent>
      </Dialog>
      {dataLoading ? (
        <LoadingPage />
      ) : (
        <div>
          <StyledPaper elevation={6}>
            {isPayment ? (
              <Payment
                contactData={contactData}
                organizationData={organizationData}
                amount={formPrice}
                userId={businessid}
                businessId={businessid}
                locationId={locationid}
                setPaymentId={setPaymentId}
                formId={formid}
                inquiryform
              />
            )
              : (
                <>
                  <Box className="pl-4">
                    <CalloutTypo variant="h1">{callOutText}</CalloutTypo>
                    <StyledTypoBody variant="body1" className="mt-2">{callOutBody}</StyledTypoBody>
                  </Box>
                  <br />
                  <FormGenerator
                    content={content}
                    onFormSubmit={onFormSubmit}
                    defaultCountryPhoneCode={defaultCountryPhoneCode}
                    rows={rows}
                    btnName={btnName}
                    radioPos={radioPos}
                    checkBoxPos={checkBoxPos}
                    colorCode={colorCode}
                    formWidth={formWidth}
                    backgroundColorCode={backgroundColorCode}
                    foreColor={foreColor}
                    fontColorCode={fontColorCode}
                    locationid={locationid}
                    btnPos={btnPos}
                    textPos={textPos}
                    btnThemeColor={btnThemeColor}
                    btnTextColor={btnTextColor}
                    termDesc={termDesc}
                    enableTerm={enableTerm}
                    enableCaptcha={enableCaptcha}
                    isInquiry
                    eventType={eventType}
                    formData={submittedData}
                    businessid={businessid}
                  />
                  <br />
                  <center>
                    <PoweredByLogo enablePublicLogo={enablePublicLogo} id={locationid} />
                  </center>
                </>
              )}
          </StyledPaper>
        </div>
      )}
    </>
  );
}
