import { Box, Paper, Typography } from '@mui/material'
import React from 'react';
import useStyles from './styles';
import ErrorBixLogo from '../../images/PageNotFound.png'
import { Link } from 'react-router-dom';

function ErrorPage() {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <div className={classes.pageContainer}>
                <Box className={classes.errorLogo}>
                    <img src={ErrorBixLogo} className={classes.logoImg} alt='logo' />
                </Box>
                <Box className={classes.errorPage}>
                    <Paper elevation={3} className={classes.paperContainer}>
                        <Typography color='primary' className={classes.errorType}>
                            404
                        </Typography>
                        <Typography color='primary' className={classes.errorMsg}>
                            Oops.Looks like the page
                            <Typography color='primary' className={classes.commonFont}>
                                you're looking for no longer
                            </Typography>
                            <Typography color='primary' className={classes.commonFont}>
                                exists
                            </Typography>
                        </Typography>
                        <Typography className={classes.bixMsg}>
                            But we're here to bring You
                            <Typography className={classes.bixMsg}>
                                back to safety
                            </Typography>
                        </Typography>
                        <Link to='https://app.bixjet.com/' target="_blank" className={classes.backToBtn}>
                            Back to Home
                        </Link>
                    </Paper>
                </Box>
            </div>
        </Box>
    )
}

export default ErrorPage