import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './CustomReactPhoneInput.css';
import { FormHelperText, Typography } from '@material-ui/core';
import { handleUndefined, postDataRequestPublic } from '../../common/commonfunctions';
import config from '../../config';
import { Entity } from '../../Api Mutation/EntityApi';
import { Box } from '@mui/material';

export default function PhoneNumbeInquiry({
  input,
  status,
  inputBg,
  inputColor,
  inputDisclaimerClr,
  onErrorRemove,
  isFromGenerator,
  inputPlaceHolder,
  lId,
  handleChange,
}) {
  const [error, setError] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState('');

  useEffect(() => {
    let isMounted = true;
    if ((lId) && isMounted) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: {
          entityid: lId,
        },
      }).then((country) => {
        if (country?.data?.entity?.defaultcountrycode) {
          setDefaultCountry(country?.data?.entity?.defaultcountrycode);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [lId]);
  
  return (
    <Box sx={{display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'start',}}>
    <PhoneInput
        {...input}
        isValid={(value, country) => {
                    if (value.length < 11 && value.length > 2 && country) {
                      setError(true);
                      return false;
                    }
                    setError(false);
                    return true;
                  }}
        country={`${defaultCountry || 'us'}`}
        onFocus={() => {
          if (isFromGenerator && onErrorRemove) {
            onErrorRemove();
          }
        }}
        onChange={handleChange}
        inputStyle={{
          backgroundColor: inputBg || 'transparent',
          color: inputColor || 'inherit',
        }}
        placeholder={handleUndefined(inputPlaceHolder) || "Enter Phone Number"}
        variant="outlined"
        disabled={status}
      />
      {error ? <FormHelperText error={error} variant="contained" id="component-error-text"><Typography  variant="body2">Phone number is invalid</Typography></FormHelperText> : ''}
      <Typography variant="body2" style={{ color: inputDisclaimerClr || 'inherit', fontSize: 10 }}>Select Country Code</Typography>
    </Box>  
  );
}
