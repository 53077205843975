/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStyles from "../styles";
import {
  BrandSettingByLocation,
  Entity,
} from "../../../Api Mutation/EntityApi";
import config from "../../../config";
import { postDataRequestPublic } from "../../../common/commonfunctions";
import { ProductServicesbyid } from "../../../Api Mutation/Product&ServiceApi";
import { createOrGetContact } from "../../../Api Mutation/Contact";
import Contactinfo from "../../ContactInfo/ContactInfo";
import {
  CRMLifecyclestageByStatus,
  StripeCurrency,
  TochlessPubilcStatus,
} from "../../../common/const";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  Link,
  Typography,
  styled,
} from "@mui/material";
import { Skeleton } from '@mui/lab';
import { CheckCircleOutline, ShoppingCartOutlined } from "@mui/icons-material";
import Payment from "../../Setting/PaymentConnection/Payment";
import { showNotification } from "../../../components/Notification/ToastifyNotifications";
import PoweredByLogo from "../../../common/PoweredByLogo";
import BusinessFavicon from "../../../common/BusinessFavicon";

export default function PublicProduct() {
  const classes = useStyles();
  const { pid, lid, uid } = useParams([]);
  const [productData, setProductData] = useState();
  const [visible, setVisible] = useState(false);
  const [contactData, setContactData] = useState();
  const [enablePublicLogo, setEnablePublicLogo] = useState(true);
  const [thankYou, setThankYou] = useState(false);
  const [locationData, setLocationData] = useState();
  const [brandImage, setBrandImage] = useState();
  const [favIcon, setFavicon] = useState(null);
  const [locationBrandImage, setLocationBrandImage] = useState();
  const [popupThankYouMessage, setPopupThankYouMessage] = useState();
  const [popupThankYouButtonText, setPopupThankYouButtonText] = useState();
  const [popupThankYouUrl, setPopupThankYouUrl] = useState("");
  const [showThankYouButton, setShowThankYouButton] = useState(false);
  const [thankYouFileUrl, setThankYouFileUrl] = useState();
  const [thanksMsg, setThanksMsg] = useState(popupThankYouMessage);
  const [paymentId, setPaymentId] = useState([]);
  const [currencyValue, setCurrencyValue] = useState("");

  const Valuetypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 600,
  }));

  const StyledTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Open sans",
  }));

  const SaleTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Open sans",
    fontWeight: 600,
    alignItems: 'center',
    display: 'flex',
    gap: '3px',
  }));
  const ActualTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Open sans",
    fontWeight: 600,
    fontSize: 12,
    marginTop: 3,
   
  }));

  const WrapperBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start !important',
    justifyContent: 'space-between'
  }));

  const ProductTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Open sans",
    fontSize: "1.5rem",
    color: '#4a4a4a'
  }));

  const ShortTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Open sans",
    fontSize: 14,
    fontWeight: 600,
    color: '#6e6e6e !important',
  }));

  const LongTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Open sans",
    fontSize: 12,
    fontWeight: 400,
    paddingTop: "12px",
    color: "#4A4A4A !important",
  }));

  const LabelTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Open sans",
    fontSize: "12px",
  }));

  const getLocationByData = () => {
    if (lid) {
      const ENTITYDATA = Entity;
      postDataRequestPublic(config.baseURLApi, {
        query: ENTITYDATA,
        variables: { entityid: lid },
      }).then((res) => {
        if (res?.data?.entity) {
          setLocationData(res?.data?.entity);
          setLocationBrandImage(res?.data?.entity?.logourl);
          setFavicon(res?.data?.entity?.favicon);
        }
      });
    }
    if (uid) {
      postDataRequestPublic(config.baseURLApi, {
        query: BrandSettingByLocation,
        variables: {
          locationid: uid,
        },
      }).then((res) =>
        setBrandImage(res?.data?.brandSettingByLocation?.logourl)
      );
    }
  };

  useEffect(() => {
    getLocationByData();
  }, [lid, uid]);

  const getInitialData = () => {
    postDataRequestPublic(config.baseURLApi, {
      query: ProductServicesbyid,
      variables: {
        productserviceid: pid,
      },
    }).then((response) => {
      if (response?.data?.productServicesbyid) {
        setProductData(response?.data?.productServicesbyid);
        setCurrencyValue(response?.data?.productServicesbyid?.currency);
        setEnablePublicLogo(
          response?.data?.productServicesbyid?.isshowfooterlogo
        );
        setShowThankYouButton(
          response?.data?.productServicesbyid?.isshowthanksbutton
        );
        setPopupThankYouUrl(
          response?.data?.productServicesbyid?.thanksbuttonlink
        );
        setPopupThankYouButtonText(
          response?.data?.productServicesbyid?.thanksbuttontext
        );
        setPopupThankYouMessage(
          response?.data?.productServicesbyid?.thanksmessage
        );
        setThankYouFileUrl(
          response?.data?.productServicesbyid?.thanksbuttonfileurl
        );
      } else if (response?.errors[0]?.message) {
        showNotification(
          response?.errors && response?.errors[0]?.message,
          "error"
        );
      }
    });
  };

  const createContact = (firstName, lastName, phoneNumber, email) => {
    const CREATE_CONTACT_MUTATION = createOrGetContact;
    const msg = popupThankYouMessage?.replace(
      /{{Name}}/g,
      firstName ? `${firstName} ${lastName || ""}` : ""
    );
    setThanksMsg(msg);
    postDataRequestPublic(config.baseURLApi, {
      query: CREATE_CONTACT_MUTATION,
      variables: {
        primaryemail: email,
        firstname: firstName,
        lastname: lastName,
        ownerid: uid,
        phonenumber: phoneNumber,
        locationid: lid,
        status: TochlessPubilcStatus?.Warm,
        lifecyclestage: CRMLifecyclestageByStatus?.Customer,
        productid: pid,
      },
    }).then((res) => {
      if (res?.errors && res?.errors[0]?.message) {
        showNotification(res?.errors[0]?.message, "error");
      } else if (res?.data?.createOrGetContact) {
        setContactData(res?.data?.createOrGetContact);
        setVisible(true);
        if (productData?.salesprice <= 0) {
          setThankYou(true);
        }
        showNotification("Data updated successfully", "success");
      }
    });
  };

  useEffect(() => {
    getInitialData();
  }, [pid]);

  useEffect(() => {
    if (paymentId) {
      postDataRequestPublic(config.baseURLApi, {
        query: createOrGetContact,
        variables: {
          primaryemail: contactData?.primaryemail,
          firstname: contactData?.firstname,
          lastname: contactData?.lastname,
          ownerid: uid,
          phonenumber: contactData?.phonenumber,
          locationid: lid,
          status: TochlessPubilcStatus?.Warm,
          lifecyclestage: CRMLifecyclestageByStatus?.Customer,
          productid: pid,
          paymentid: paymentId,
        },
      });
    }
  }, [paymentId]);

  return (
    <>
      <BusinessFavicon
        name={productData?.name}
        logo={favIcon || locationBrandImage || brandImage}
      />
      <Dialog
        open={thankYou}
        onClose={() => {
          setThankYou(false);
        }}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xs"
      >
        <DialogContent id="responsive-dialog-title" style={{ padding: 20 }}>
          <center>
            <CheckCircleOutline style={{ fontSize: 70, color: "green" }} />
          </center>
          <StyledTypo
            variant="body1"
            dangerouslySetInnerHTML={{ __html: thanksMsg }}
          />
          <br />
          {showThankYouButton && popupThankYouUrl && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => {
                window.open(popupThankYouUrl, "_blank");
              }}
            >
              {popupThankYouButtonText}
            </Button>
          )}
          {showThankYouButton && thankYouFileUrl && (
            <Link href={thankYouFileUrl} target="_blank" download>
              <Button variant="contained" size="large" color="primary">
                {popupThankYouButtonText}
              </Button>
            </Link>
          )}
          <center>
            <PoweredByLogo
              enablePublicLogo={enablePublicLogo}
              id={locationData?.entityid}
            />
          </center>
        </DialogContent>
      </Dialog>
      <Box className={classes.productCardDetails}>
        <Card className={classes.rootDetails}>
          <Box className={classes.details}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} md={1} lg={2}>
                {locationData?.logourl ? (
                  <img
                    className={classes.businessLogo}
                    src={locationData?.logourl}
                    alt="logo"
                  />
                ) : brandImage ? (
                  <img
                    className={classes.businessLogo}
                    src={brandImage}
                    alt="logo"
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} md={12} lg={3}>
                <LabelTypo color="primary" variant="body2">
                  Business Name:
                </LabelTypo>
                <Valuetypo variant="body1">
                  {locationData?.businessname}
                </Valuetypo>
              </Grid>
              <Grid item xs={12} md={12} lg={3}>
                <LabelTypo color="primary" variant="body2">
                  Product Name:
                </LabelTypo>
                <Valuetypo variant="body1">{productData?.name}</Valuetypo>
              </Grid>
              <Grid item xs={12} md={1} lg={1} />
              <Grid item xs={12} md={12} lg={2}>
                <LabelTypo color="primary" variant="body2">
                  Price:
                </LabelTypo>
                <Valuetypo variant="body1" style={{ width: "82px" }}>
                  <span>
                    {
                      StripeCurrency?.find((c) => c?.value === currencyValue)
                        ?.symbol
                    }{" "}
                    {productData?.salesprice || 0}{" "}
                  </span>
                  <span className={classes.currencyText}>
                    (
                    {
                      StripeCurrency?.find((c) => c?.value === currencyValue)
                        ?.value
                    }
                    )
                  </span>
                </Valuetypo>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <center>
              {productData?.imgurl ? (
                <img
                  className={classes.mediaDetails}
                  src={productData?.imgurl}
                  alt="img"
                />
              ) : (
                <Skeleton variant="rectangular" className={classes.mediaDetails} />
              )}
            </center>
            <hr />
            <CardContent>
              <Grid container direction="row">
                <Grid item xs={12} md={12}>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <WrapperBox>
                        <ProductTypo gutterBottom variant="h4" component="h4">
                          {productData?.name}
                        </ProductTypo>
                        <Box
                          className={classes.price}
                          style={{ alignItems: "center", float: "right" }}
                        >
                          <ShoppingCartOutlined
                            fontSize="large"
                            color="primary"
                            className={classes.cart}
                          />
                          &nbsp;
                          {productData?.salesprice > 0 ? (
                            <>
                              <SaleTypo variant="h6">
                                <span>
                                  {
                                    StripeCurrency?.find(
                                      (c) => c?.value === currencyValue
                                    )?.symbol
                                  }{" "}
                                  {productData?.salesprice}{" "}
                                </span>
                                <span style={{ fontSize: '15px'}}>
                                  (
                                  {
                                    StripeCurrency?.find(
                                      (c) => c?.value === currencyValue
                                    )?.value
                                  }
                                  )
                                </span>
                              </SaleTypo>
                              &nbsp;
                              <ActualTypo variant="subtitle1">
                                <strike>
                                  <span>
                                    {
                                      StripeCurrency?.find(
                                        (c) => c.value === currencyValue
                                      )?.symbol
                                    }{" "}
                                    {productData?.actualprice}{" "}
                                  </span>
                                  <span className={classes.currencyText}>
                                    (
                                    {
                                      StripeCurrency?.find(
                                        (c) => c?.value === currencyValue
                                      )?.value
                                    }
                                    )
                                  </span>
                                </strike>
                              </ActualTypo>
                            </>
                          ) : (
                            <>
                              <StyledTypo variant="h6" color="primary">
                                Free
                              </StyledTypo>
                              &nbsp;&nbsp;
                              {productData?.actualprice > 0 ? (
                                <StyledTypo variant="subtitle1">
                                  <strike>
                                    <span>
                                      {
                                        StripeCurrency?.find(
                                          (c) => c.value === currencyValue
                                        )?.symbol
                                      }{" "}
                                      {productData?.actualprice}{" "}
                                    </span>
                                    <span className={classes.currencyText} >
                                      (
                                      {
                                        StripeCurrency?.find(
                                          (c) => c?.value === currencyValue
                                        )?.value
                                      }
                                      )
                                    </span>
                                  </strike>
                                </StyledTypo>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </Box>
                        </WrapperBox>
                        <Box>
                          <br/>
                        <ShortTypo
                          variant="body1"
                          color="textSecondary"
                          component="p"
                        >
                          {productData?.shortdiscription}
                        </ShortTypo>
                        <LongTypo
                          variant="body2"
                          dangerouslySetInnerHTML={{
                            __html: productData?.longdiscription,
                          }}
                        />
                        </Box>
                       
                       
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {visible === false ? (
                          <>
                            <div>
                              <Contactinfo
                                createContact={createContact}
                                productData={productData}
                              />
                            </div>
                          </>
                        ) : visible === true &&
                          productData?.salesprice > 0 &&
                          thankYou === false ? (
                          <Box className={classes.paymentBox}>
                            <Payment
                              product={productData?.name}
                              userId={uid}
                              businessId={uid}
                              locationId={lid}
                              objectId={pid}
                              setThankYou={setThankYou}
                              contactData={contactData}
                              amount={productData?.salesprice}
                              setPaymentId={setPaymentId}
                              currencyValue={
                                StripeCurrency?.find(
                                  (c) => c?.value === currencyValue
                                )?.value
                              }
                            />
                          </Box>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <br />
                </Grid>
              </Grid>
            </CardContent>
            <br />
          </Box>
        </Card>
      </Box>
      <PoweredByLogo
        enablePublicLogo={enablePublicLogo}
        id={locationData?.entityid}
      />
    </>
  );
}
